// dashboard
export const superAdmin = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
];
export const sanitationDash_param = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  // "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
];
export const tentageDash_param = ["1", "2", "3", "4"];
// export const tentageDash_param = ["1", "2", "3", "4", "8"];
export const wasteDash_param = ["1", "2", "3", "4"];
export const incidentDash_param = ["1", "2", "3", "4"];
export const vendorDash_param = ["8"]; // vendor dashboard
export const SLADash_param = ["1", "2", "3", "4"];

// user access and registration
export const userAccess_param = ["1", "2"];

// master data creation
export const masterData_param = ["1", "2"];

// DMS
export const DMS_param = ["1", "2", "3", "4"];

// Reports
export const reports_param = ["1", "2", "3", "4", "8"];
export const monitoring_reports = ["1", "2", "3", "4", "8"];
export const sector_wise_reports = ["1", "2", "3", "4", "8"];
export const circle_wise_reports = ["1", "2", "3", "4"];
export const vendor_wise_reports = ["1", "2", "3", "4"];
export const incident_reports = ["1", "2", "3", "4"];
export const inspections_reports = ["1", "2", "3", "4"];
export const gsd_wise_regi_reports = ["1", "2", "3", "4"];
export const vendor_wise_regi_reports = ["1", "2", "3", "4"];
